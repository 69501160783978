var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"6"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CCard',[_c('CCardBody',[_c('h4',[_vm._v(" Menus ")]),_c('CButton',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addMenu()}}},[_vm._v("Add Menu")]),_c('CDataTable',{attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"menu_elements",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.menuElements(item.id)}}},[_vm._v("Menu Elements")])],1)]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editMenu(item.id)}}},[_vm._v("Edit")])],1)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteMenu(item.id)}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }